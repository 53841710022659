import * as React from "react";

import { Admin, Resource } from 'react-admin';
import { KursCreate, KursEdit, KursIcon, KursList } from './src/KurseView';
import { TerminCreate, TerminEdit } from './src/TerminView';

import MyLoginPage from "./src/LoginPage";
import authProvider from "./src/authProvider";
import { createRoot } from 'react-dom/client';
import { i18nProvider } from "./src/i18nProvider";
import restProvider from 'ra-data-simple-rest';

let {NODE_ENV} = process.env;
if(NODE_ENV == "local"){
    require('dotenv').config({ path: '.env.local' })
}else if(NODE_ENV == "production"){
    require('dotenv').config({ path: '.env.production' })
}
let { API_TOKEN} = process.env;


const container = document.getElementById('root');
const root = createRoot(container);

root.render( 
<Admin dataProvider={restProvider(process.env.API_URL)} i18nProvider={i18nProvider} loginPage={MyLoginPage} authProvider={authProvider} requireAuth>
    <Resource name="kurse" options={{ label: 'Kurs' }} list={KursList} edit={KursEdit} create={KursCreate} icon={KursIcon}/>
    <Resource name="termine" options={{ label: 'Termine' }} create={TerminCreate} edit={TerminEdit}/>
</Admin>);
