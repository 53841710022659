import * as React from "react";

import { AddItemButton, Create, Datagrid, DateField, DateInput, Edit, EditButton, List, Resource, SimpleForm, TextField, TextInput, useRecordContext } from 'react-admin';
import { TerminCreate, TerminEdit, TerminList } from "./TerminView";

import { BackButton } from "./uiHelper";
import BookIcon from '@mui/icons-material/Book';

export const KursIcon = BookIcon;

export const KursList = () => (
    <List>
        <Datagrid>
            <TextField source="id" label="Kurs ID" />
            <TextField source="description" label="Interne Bezeichnung" />
            <DateField source="date" label="Datum"/>
            <EditButton />
        </Datagrid>
    </List>
);

const KursTitle = () => {
    const record = useRecordContext();
    const month = ["Jänner","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"];
    const d = new Date(record?.date);

    return <span>Kurs {month[d.getMonth()]}</span>;
};

export const KursEdit = () => {
    
    return <Edit title={<KursTitle />}>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
            <h3 style={{padding: "12px"}}>Bearbeite <KursTitle /></h3>
            <BackButton></BackButton>
        </div>
        <SimpleForm>
            <DateInput source="date" label="Datum"/>
            <TextInput source="description" label="Interne Bezeichung"/>
            <h4>Termine</h4>
            <Resource name="termine" options={{ label: 'Termine' }} hasEdit hasCreate list={TerminList} create={TerminCreate} edit={TerminEdit}/>
        </SimpleForm>
    </Edit>
};

export const KursCreate = () => (
    <Create title="Erstelle einen Kurs">
        <BackButton></BackButton>
        <SimpleForm>
            <DateInput source="date" label="Datum" />
            <TextInput source="description" label="Interne Bezeichung"/>
        </SimpleForm>
    </Create>
);
