import * as React from "react";

import { Button, Create, CreateButton, CustomRoutes, Datagrid, DateField, DateInput, Edit, EditButton, List, Resource, SelectField, SelectInput, SimpleForm, TextField, TextInput, TimeInput, useRecordContext } from 'react-admin';
import { Link, useParams } from 'react-router-dom';

import { BackButton } from "./uiHelper";
import BookIcon from '@mui/icons-material/Book';

export const KursIcon = BookIcon;

var selectedId;

export const TerminList = () => {
    const { id } = useParams();
    selectedId = id;
    return <List filter={{id}}>
                <Datagrid>
                    <DateField source="date" label="Datum"/>
                    <TextField source="starttime" label="Start Zeit"/>
                    <TextField source="endtime" label="End Zeit"/>
                    <TextField source="groupname" label="Zielgruppe"/>
                    <EditButton />
                </Datagrid>
            </List>
};

const TerminTitle = () => {
    const record = useRecordContext();
    return <span>Termin { record ? `"${record.date}"` : '' }</span>;
};

export const TerminEdit = () => (
    <Edit title={<TerminTitle />} redirect={"/kurse/" + selectedId}>
        <BackButton></BackButton>
        <SimpleForm>
            <DateInput source="date" label="Datum"/>
            <TextInput source="starttime" label="Start Zeit"/>
            <TextInput source="endtime" label="End Zeit"/>
            <SelectInput source="groupname" label="Zielgruppe" choices={[
                                            { id: 'Paareinheit', name: 'Paareinheit' },
                                            { id: 'Fraueneinheit', name: 'Fraueneinheit' },
            ]}/>   
            <TextInput source="kursid" value={selectedId} style={{display: "none"}}></TextInput>     
        </SimpleForm>
    </Edit>
);

export const TerminCreate = (options) => {
    return <Create title="Erstelle einen Termin" redirect={"/kurse/" + selectedId}>
        <BackButton></BackButton>
        <SimpleForm>
            <DateInput source="date" label="Datum"/>
            <TextInput source="starttime" label="Start Zeit"/>
            <TextInput source="endtime" label="End Zeit"/>
            <SelectInput source="groupname" label="Zielgruppe" choices={[
                                            { id: 'Paareinheit', name: 'Paareinheit' },
                                            { id: 'Fraueneinheit', name: 'Fraueneinheit' },
            ]}/> 
            <TextInput source="kursid" defaultValue={selectedId} style={{display: "none"}}></TextInput>     
        </SimpleForm>
    </Create>
};

