// src/MyLoginPage.js
import * as React from 'react';

import { Button, Card, CardActions, CardContent, TextField } from '@mui/material';
import { Layout, Notification, useLogin, useNotify } from 'react-admin';

import authProvider from './authProvider';

const MyLoginPage = () => {
    const login = useLogin();
    const notify = useNotify();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const submit = (e) => {
        e.preventDefault();
        login({ username, password }).catch(() =>
            notify('Invalid username or password')
        );
    };

    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "70vh", gap: "5%", flexWrap: "wrap"}}>
        <span style={{ width: "auto", height: "auto"}} className='login-header'>
            <h1>Anmelden</h1>
            <h2>GemeinsamInsLeben Adminportal</h2>
        </span>
        <form onSubmit={submit} style={{ minWidth: "300px", width: "30%"}}>
            <Card>
                <CardContent>
                    <div>
                        <TextField
                            label="Username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            fullWidth
                        />
                    </div>
                    <div>
                        <TextField
                            label="Password"
                            name="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                        />
                    </div>
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                        Login
                    </Button>
                </CardActions>
            </Card>
            <Notification />
        </form>
        </div>
    );
};

export default MyLoginPage;
