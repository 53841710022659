
// src/authProvider.js
const authProvider = {
    // Authentication logic for login
    login: ({ username, password }) => {
        const request = new Request(process.env.API_URL + "/login", {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then( (data) => {
                localStorage.setItem('authToken', data.data);
            });
    },
    
    // Called when user logs out
    logout: () => {
        localStorage.removeItem('authToken');
        return Promise.resolve();
    },
    
    // Called when API request requires authentication
    checkAuth: () => {
        return localStorage.getItem('authToken') ? Promise.resolve() : Promise.reject();
    },
    
    // Called to check permissions
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('authToken');
            return Promise.reject();
        }
        return Promise.resolve();
    },

};

export default authProvider;
